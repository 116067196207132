// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "../controllers"

import * as bootstrap from "bootstrap";
require("@rails/ujs").start()
require("turbolinks").start()
import '../stylesheets/application';

// Import jQuery
import $ from "jquery";
global.$ = $;
global.jQuery = $;

// Import select2
require('select2');
require('select2/dist/css/select2.css');

// Import flatpick library for datepicker
require("flatpickr");
require("flatpickr/dist/flatpickr.min.css");

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
})

require('admin-lte');
import "@fortawesome/fontawesome-free/js/all";
